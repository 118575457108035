import React, { useContext } from 'react';
import { node } from 'prop-types';
import { useSession } from 'next-auth/react';
import Login from '../Login';
import LoadingIndicator from '../LoadingIndicator';
import ReservationContext from '../../../providers/ReservationContext';

const Layout = ({ children }) => {
  const { data: session, status } = useSession();
  const reservation = useContext(ReservationContext);

  if (reservation?.isLoading || status === 'loading')
    return <LoadingIndicator message='Getting your reservation' loadingReason='login or getReservation' />;
  if (!session) return <Login />;

  return children;
};

Layout.propTypes = {
  children: node.isRequired,
};

export default Layout;
