import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import { signIn, useSession } from 'next-auth/react';
import Link from '../../Link';
import Form from '../Form';
import Input from '../Input';
import Alert from '../Alert';
import LoadingButton from '../LoadingButton';
import { track } from '../../../modules/segment/helpers';

const Login = () => {
  const router = useRouter();
  const { data: session } = useSession();
  const [confirmationCode, updateConfirmationCode] = useState(router.query?.code || router.query?.c || '');
  const [zipCode, updateZipCode] = useState(router.query?.zip || '');
  const [error, updateError] = useState(null);
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    router.replace(router.pathname, undefined, { shallow: true });
    track('Dashboard Login Viewed', { confirmationCode, zipCode });
  }, []);

  return (
    <Box display='flex' alignItems='center' mt={{ xs: 5, md: 11 }} mx={{ xs: 3, md: 0 }} flexDirection='column'>
      <Box maxWidth={400}>
        <Typography variant='h3' gutterBottom>
          Welcome, sign in to your dashboard below.
        </Typography>
        <Typography variant='body1' color='textSecondary'>
          Enter your reservation code (your code can be found in your confirmation email) and zip code.
        </Typography>
        {error && (
          <Box mt={3}>
            <Alert
              type='error'
              text='Cannot find reservation, check to see if reservation code and starting zip are correct.'
            />
          </Box>
        )}
        {/* <LoadingButton
          type='button'
          variant='contained'
          loading={session?.status === 'loading'}
          sx={{
            width: { md: 240 },
            marginTop: 5,
          }}
          onClick={() => {
            signIn('auth0');
            track('Dashboard Login Button Clicked', {});
          }}
        >
          Login with Email
        </LoadingButton> */}
        <Form
          onValid={() => {
            setSubmitClicked(true);
            signIn('credentials', {
              redirect: false,
              zipCode,
              confirmationCode,
            }).then((res) => {
              if (res.error) {
                updateError(res.error);
                setSubmitClicked(false);
              }
            });
          }}
        >
          <Box mt={3}>
            <Input
              label='Reservation code'
              variant='standard'
              inputProps={{
                minLength: 8,
                maxLength: 8,
              }}
              pattern='[A-Za-z0-9_]{8,}'
              required
              helperText='Please enter a valid confirmation number.'
              value={confirmationCode}
              onChange={({ target: { value } }) => updateConfirmationCode(value)}
            />
          </Box>
          <Box mt={3}>
            <Input
              label='Zip code'
              variant='standard'
              pattern='[0-9]{5}'
              required
              inputProps={{
                inputMode: 'numeric',
                minLength: 5,
                maxLength: 5,
              }}
              helperText='Please enter a valid 5-digit starting zip code.'
              value={zipCode}
              onChange={({ target: { value } }) => updateZipCode(value)}
            />
          </Box>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={Boolean(submitClicked && !session)}
            sx={{
              width: { md: 240 },
              marginTop: 5,
            }}
            onClick={track('Dashboard Login Button Clicked', {})}
          >
            Go to Reservation
          </LoadingButton>
        </Form>
        <Box mt={5}>
          <Typography variant='body1' color='textSecondary'>
            Don&apos;t have a reservation?
          </Typography>
          <Link underline='none' variant='body1' href={`${process.env.NEXT_PUBLIC_BOOKING_SITE_URL}/book/service`} external>
            Book now →
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
