import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Clear, CurrencyExchange } from '@mui/icons-material';
import { Box, Button, Dialog, Typography, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { bool, func, string } from 'prop-types';

import RESERVATION_STATUS from '../../constants/reservationStatus';
import { track } from '../../modules/segment';
import ReservationContext from '../../providers/ReservationContext';
import Divider from '../shared/Divider';
import BabyTimeline from './BabyTimeline';
import ContextualActions from './ContextualActions';
import QuickActions from './QuickActions';
import getActionsData from './progressMenuData/actionsData';
import getQuickActionsData from './progressMenuData/quickActionsData';
import stepsData from './progressMenuData/stepsData';

const ReferralDialog = ({ open, onClose, referralAccountId }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [clipboardButtonText, setClipboardButtonText] = useState('Copy link to clipboard');

  const resetButtonText = () => {
    setTimeout(() => setClipboardButtonText('Copy link to clipboard'), 250);
  };

  const referralLink = `${process.env.NEXT_PUBLIC_BOOKING_SITE_URL}/v2/book/service?utm_medium=CRM&utm_source=customer-referral&utm_content=owned:crm:referral:na:na:na:na&utm_campaign=${referralAccountId}&pc=GiveGet50`;

  const copyReferralLinkToClipboard = () => {
    if (navigator?.clipboard) {
      track('Dashboard - Customer Referral Link copied to clipboard');
      navigator.clipboard.writeText(referralLink);
      setClipboardButtonText('Copied to clipboard');
    }
  };

  const close = () => {
    resetButtonText();
    onClose();
  };

  return (
    <Dialog open={open} onClose={close} fullScreen={Boolean(mobile)}>
      <Box maxWidth={380} minWidth={300}>
        <Box display='flex' justifyContent='flex-end' p={1} pb={0}>
          <Box display='flex' p={1} sx={{ '&:hover': { cursor: 'pointer' } }}>
            <Clear onClick={close} />
          </Box>
        </Box>
        <Box px={2} pb={4}>
          <Box pb={2}>
            <Typography variant='h4' textAlign='center'>
              Give $50, Get $50
            </Typography>
          </Box>
          <Divider />
          <Box pt={3} px={2}>
            <Typography variant='body1' textAlign='center'>
              When you refer a friend, they save $50, and you earn a $50 rebate when they complete their move.
            </Typography>
          </Box>
          <Box pt={3} px={2}>
            <Typography variant='body1' textAlign='center'>
              Use the button below to copy the link to your clipboard and send to your friends.
            </Typography>
          </Box>
          <Box pt={3} px={4}>
            <Button onClick={copyReferralLinkToClipboard}>{clipboardButtonText}</Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

ReferralDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  referralAccountId: string.isRequired,
};

const ProgressMenu = () => {
  dayjs.extend(isToday);

  const theme = useTheme();
  const { status, transactions, jobs, permissions, orderCode } = useContext(ReservationContext);

  const [stage, setStage] = useState('');
  const [step, setStep] = useState(0);
  const [referralDialogOpen, setReferralDialogOpen] = useState(false);

  const [referralAccountId, setReferralAccountId] = useState();

  const actions = getActionsData({ transactions, status, jobs, permissions })
    .filter(({ context = [] }) => context.some(({ id = '' }) => id === stage))
    .sort(({ context: contextA = {} }, { context: contextB = {} }) => {
      const { priority: priorityA = 100 } = contextA.find(({ id = '' }) => id === stage);
      const { priority: priorityB = 100 } = contextB.find(({ id = '' }) => id === stage);

      return priorityA - priorityB;
    });

  const quickActions = useMemo(
    () =>
      [
        ...(referralAccountId
          ? [
              {
                Icon: CurrencyExchange,
                label: 'Give $50, Get $50',
                description:
                  'Refer family or friends, they save $50, and you get a $50 rebate when they complete their move.',
                linkText: 'Refer now',
                stepIDs: ['movePrep', 'moveDay', 'postMove'],
                displayOnMobile: true,
                onClick: () => {
                  track('Dashboard - Customer Referral Dialog Opened');
                  setReferralDialogOpen(true);
                },
              },
            ]
          : []),
        ...(getQuickActionsData() ?? []),
      ].filter(({ stepIDs = [] }) => stepIDs.includes(stage)),
    [referralAccountId],
  );

  useEffect(() => {
    if (orderCode) {
      try {
        fetch(`${process.env.NEXT_PUBLIC_WEBPROXY_API_URL}/v1/public/dashboard/order_account_id?order_id=${orderCode}`).then(
          async (response) => {
            if (!response.ok) {
              return;
            }

            const { accountId } = await response.json();
            setReferralAccountId(accountId);
          },
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }, [orderCode]);

  useEffect(() => {
    if (stage) {
      setStep(stepsData.findIndex(({ id }) => id === stage));
    }
  }, [stage]);

  useEffect(() => {
    if (!jobs) return;

    const reservationStartDate = dayjs.unix(jobs[0].start);

    if (status === RESERVATION_STATUS.COMPLETED) {
      setStage('postMove');
    } else if (reservationStartDate.isToday()) {
      setStage('moveDay');
    } else {
      setStage('movePrep');
    }
  }, []);

  const updateTimeline = (selectedStage) => {
    setStage(selectedStage);
  };

  return (
    <>
      <ReferralDialog
        open={referralDialogOpen}
        onClose={() => setReferralDialogOpen(false)}
        referralAccountId={referralAccountId}
      />
      <Box display={{ xs: 'block', md: 'flex' }} alignItems='start' marginTop={{ xs: 0, md: 3.5 }}>
        <Box flexGrow={2}>
          <Typography display={{ xs: 'none', md: 'block' }} variant='h4' component='h2' sx={{ marginBottom: 2 }}>
            Timeline and actions
          </Typography>
          <Box
            padding={{ xs: 0, md: theme.spacing(6, 0) }}
            bgcolor={{ md: theme.palette.neutral.lightest }}
            borderRadius={{ xs: 0, md: '10px' }}
            sx={{
              border: {
                xs: 'none',
                md: `1px solid ${theme.palette.neutral.light}`,
              },
            }}
          >
            <BabyTimeline steps={stepsData} activeStep={step} onClick={updateTimeline} />
            <Box marginTop={4}>
              <ContextualActions actions={actions} />
            </Box>
          </Box>
        </Box>
        <Box flexGrow={1} maxWidth={{ md: 300 }} ml={{ md: 6 }}>
          <Typography display={{ xs: 'none', md: 'block' }} variant='h4' component='h2'>
            Add-ons and extras
          </Typography>
          <QuickActions quickActions={quickActions} />
        </Box>
      </Box>
    </>
  );
};

export default ProgressMenu;
