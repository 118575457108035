import { useContext } from 'react';

import { LocalMallOutlined as LocalMallOutlinedIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { track } from '../../../modules/segment/helpers';

import phoneNumbers from '../../../constants/phoneNumbers';
import RESERVATION_STATUS from '../../../constants/reservationStatus';
import serviceQuoteIDs from '../../../constants/serviceQuoteIDs';
import ReservationContext from '../../../providers/ReservationContext';
import isWithinDates from '../../../utils/datetime/isWithinDates';

const getQuickActionsData = () => {
  const { jobs, orderCode, status } = useContext(ReservationContext);

  const isPastStartDate = dayjs().isAfter(dayjs.unix(jobs?.[0]?.start), 'day');
  const hasPackingMaterials = Boolean(jobs?.some((job) => job.service.id === serviceQuoteIDs.PACKING_MATERIALS));
  const COMPLETED = status === RESERVATION_STATUS.COMPLETED;
  const CLOSED = status === RESERVATION_STATUS.CLOSED;
  const canShowPackingMaterials = Boolean(
    !isWithinDates(jobs?.[0]?.start, 5) && !isPastStartDate && !COMPLETED && !CLOSED && !hasPackingMaterials,
  );
  const packingMaterials = canShowPackingMaterials
    ? {
        id: 'PACKINGMATERIALS',
        Icon: '/svgs/packing-materials.svg',
        label: 'Need packing materials?',
        href: `tel:${phoneNumbers.CUSTOMER_SERVICE}`,
        target: '_blank',
        description:
          'The package includes boxes, tape, paper, and bubble wrap. The packing materials will be delivered to your door.',
        linkText: `Call ${phoneNumbers.CUSTOMER_SERVICE}`,
        stepIDs: ['movePrep'],
        displayOnMobile: true,
      }
    : {};

  return [
    packingMaterials,
    {
      Icon: LocalMallOutlinedIcon,
      label: 'Quick assistance',
      href: `/contact`,
      description: 'Call or chat with a specialist now.',
      linkText: 'Contact now',
      stepIDs: ['movePrep', 'moveDay', 'postMove'],
      displayOnMobile: false,
      onClick: () => track('Add Ons and Extras - Contact Now', { orderID: orderCode }),
    },
  ];
};

export default getQuickActionsData;
