import React from 'react';
import NextLink from 'next/link';
import { ListItem, Typography, Box, useTheme, useMediaQuery, Link as MuiLink, Link } from '@mui/material';
import { elementType, string, bool } from 'prop-types';

const QuickActionCard = ({
  id,
  label,
  href,
  target,
  Icon,
  description,
  onClick: contactNowOnClick,
  linkText,
  displayOnMobile,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ListItem
      button={!!isMobile}
      onClick={contactNowOnClick}
      sx={{
        display: isMobile && !displayOnMobile ? 'none' : 'block',
        background: theme.palette.neutral.lightest,
        borderRadius: '10px',
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.neutral.light}`,
        flex: '1 0 calc(100% - 16px)',
        marginTop: 2,
        padding: 2,
        cursor: !href ? 'pointer' : 'inherit',
      }}
    >
      {/* Special packing material card temporarily until automated process in place */}
      {id === 'PACKINGMATERIALS' ? (
        <Box>
          <img src='/svgs/packing-materials.svg' alt='Stack of boxes' height={80} width={80} />
          <Typography lineHeight='1.2' variant='h4' component='h4' fontSize='1.125rem' paddingLeft={0}>
            {label}
          </Typography>
          <Box py={1.5}>
            <Typography lineHeight='1.2' variant='body1' color={theme.palette.neutral.medium}>
              {description}
            </Typography>
          </Box>
          <Link href={href} color='secondary.dark' fontFamily='CalibreMedium' variant='h5'>
            {linkText}
          </Link>
        </Box>
      ) : (
        <Box
          component={href ? NextLink : 'div'}
          {...(href ? { href } : {})}
          passHref
          target={target}
          sx={{
            color: theme.palette.text.primary,
            textDecoration: 'none',
            '& > p': {
              padding: theme.spacing(1.25, 1),
              [theme.breakpoints.up('md')]: {
                marginLeft: 0,
                padding: 0,
              },
            },
            '& .MuiTypography-root:first-of-type': {
              paddingBottom: 0,
            },
            '& .MuiTypography-root:last-of-type': {
              paddingTop: 0,
            },
          }}
        >
          <Box display='flex' alignItems='center' pb={{ md: 1 }}>
            <Icon sx={{ color: theme.palette.primary.main, marginRight: 0.5 }} />
            <Typography
              lineHeight='1.2'
              variant='h5'
              component='h3'
              fontSize={isMobile ? '1rem' : '1.125rem'}
              paddingLeft={0}
            >
              {label}
            </Typography>
          </Box>
          {!isMobile && (
            <>
              <Typography lineHeight='1.2' variant='body1' color={theme.palette.neutral.medium}>
                {description}
              </Typography>
              <MuiLink underline='hover' onClick={contactNowOnClick} component='span' fontFamily='CalibreMedium'>
                {linkText}
              </MuiLink>
            </>
          )}
        </Box>
      )}
    </ListItem>
  );
};

QuickActionCard.propTypes = {
  id: string,
  label: string.isRequired,
  href: string,
  target: string,
  description: string.isRequired,
  Icon: elementType.isRequired,
  linkText: string.isRequired,
  displayOnMobile: bool.isRequired,
  onClick: Function,
};

QuickActionCard.defaultProps = {
  id: null,
  target: '_self',
  onClick: () => {},
  href: null,
};

export default QuickActionCard;
